@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  color: white;
}

.header {
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  background-color: #2b3744;
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hero-container {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-evenly;
}

.hero {
  display: flex;
  flex: auto;
  align-items: center;
  flex-direction: column;
}

.ability-icon {
  background-color: #2b3744;
}
